import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { format, parseISO, compareAsc } from 'date-fns';
import { HashLink } from 'react-router-hash-link';
import {
  BanknotesIcon,
  ReceiptPercentIcon,
  FireIcon,
  VideoCameraIcon,
  ArrowDownTrayIcon,
  DocumentTextIcon
} from '@heroicons/react/24/outline'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import LoadingNotification from 'components/LoadingNotification';
import NumberFormat from 'react-number-format';
import urlJoin from 'url-join';

// import MonthYearSelect from 'components/Form/MonthYearSelect';
import { getClientMonthlyRoyalty, exportReport, getExports } from 'api/core';
import { getMonthlyConflicts, getMonthlyClaims } from 'api/expdata';
import { classNames } from 'components/utils';
import Spinner from 'components/Icons/Spinner';
import MonthYearDropdown from 'components/Form/MonthYearDropdown';
import InfoBar from 'components/InfoBar';

import { useTranslation } from 'react-i18next';

const LoadingCard = ({ title, icon }) => {
  return (<div className="overflow-hidden rounded-lg bg-white shadow animate-pulse">
    <div className="p-5">
      <div className="flex">
        <div className="ml-5 w-0 flex-1">
          <dl>
            <dt className="truncate text-sm font-medium text-gray-500">{title}</dt>
            <dd>
              <div className="mt-2">
                <div className="rounded-full bg-gray-200 w-1/2 h-6" />
              </div>
            </dd>
          </dl>
          <div className='flex items-center mt-2'>
            <div className="rounded-full bg-gray-200 w-3/4 h-4" />
          </div>
        </div>
        <div className="flex-shrink-0">
          <div className="rounded-full bg-gray-200 h-12 w-12 flex items-center justify-center shadow text-gray-100">
            {icon}
          </div>
        </div>
      </div>
    </div>
  </div>)
}

const RoyaltyOverview = ({ clientLoading, client, dateStart, setDateStart, dateEnd, setDateEnd }) => {
  const { t } = useTranslation();
  const history = useHistory()
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] =useState(false);
  let location = useLocation();
  const basePath = location.pathname;

  const [currentStats, setCurrentStats] = useState({
    netRevenue: 0,
    grossRevenue: 0,
    explorationShare: 0,
    reports: 0,
    netRevenueChange: null,
    explorationShareChange: null,
    reportsChange: null,
  });
  const [prevStats, setPrevStats] = useState({
    netRevenue: 0,
    grossRevenue: 0,
    explorationShare: 0,
    reports: 0
  });
  const [reports, setReports] = useState([]);
  const [conflicts, setConflicts] = useState(0);
  const [claims, setClaims] = useState(0);
  const [invalidDate, setInvalidDate] = useState(false);

  const handleDateChange = (newMonth, newYear) => {
    setDateStart({ month: newMonth, year: newYear });
    // const dtStart = new Date(newYear, newMonth - 1, 1);
    // const dtEnd = new Date(dateStart.year, dateStart.month - 1, 1);
    // if (compareAsc(dtStart, dtEnd) <= 0) {
    //   setInvalidDate(false);
    //   setDateStart({ month: newMonth, year: newYear });
    // } else {
    //   setInvalidDate(true);
    // }
  };

  const handleDateEndChange = (newMonth, newYear) => {
    const dtEnd = new Date(newYear, newMonth - 1, 1);
    const dtStart = new Date(dateEnd.year, dateEnd.month - 1, 1);
    if (compareAsc(dtStart, dtEnd) <= 0) {
      setInvalidDate(false);
      setDateEnd({ month: newMonth, year: newYear });
    } else {
      setInvalidDate(true);
    }
  };

  const compareDates = () => {
    const dtStart = new Date(dateStart.year, dateStart.month - 1, 1);
    const dtEnd = new Date(dateEnd.year, dateEnd.month - 1, 1);
    return compareAsc(dtStart, dtEnd);
  }

  useEffect(() => {
    if (client?.uid) {
      setLoading(true);
      getClientMonthlyRoyalty(client?.uid, dateStart.month, dateStart.year, dateStart.month, dateStart.year).then((res) => {
        if (res.data?.status === 'ok') {
          setCurrentStats({
            netRevenue: res.data.net_revenue,
            grossRevenue: res.data.gross_revenue,
            explorationShare: res.data.exploration_share,
            reports: res.data.reports,
            netRevenueChange: res.data.net_revenue >= res.data.prev_net_revenue,
            explorationShareChange: res.data.exploration_share >= res.data.prev_exploration_share,
            reportsChange: res.data.reports >= res.data.prev_reports,
          });
          setPrevStats({
            netRevenue: res.data.prev_net_revenue,
            grossRevenue: res.data.prev_gross_revenue,
            explorationShare: res.data.prev_exploration_share,
            reports: res.data.prev_reports
          });
          setReports(res.data.summaries);
        } else if (res.data?.status === 'no-assets') {
          setCurrentStats({
            netRevenue: 0,
            grossRevenue: 0,
            explorationShare: 0,
            reports: 0,
            netRevenueChange: null,
            explorationShareChange: null,
            reportsChange: null,
          });
          setPrevStats({
            netRevenue: 0,
            grossRevenue: 0,
            explorationShare: 0,
            reports: 0
          });
          setReports([]);
        }

        setLoading(false);
      });
    }
  }, [dateStart, client]);

  useEffect(() => {
    if (client?.name) {
      setLoading(true);
      getMonthlyConflicts(dateStart.month, dateStart.year, null, null, client?.name).then(res => {
        setConflicts(res.data?.total_assets);
        setLoading(false);
      }).catch(err => {
        console.log(err);
        setLoading(false);
      });
    }

    if (client?.name) {
      setLoading(true);
      getMonthlyClaims(dateStart.month, dateStart.year, null, null, client?.name).then(res => {
        setClaims(res.data?.total_claims);
        setLoading(false);
      }).catch(err => {
        console.log(err);
        setLoading(false);
      });
    }
  }, [dateStart, client]);

  const getExport = (uid, reportName, startMonth, startYear, endMonth, endYear) => {
    setExporting(true)
    exportReport(uid, reportName, startMonth, startYear, endMonth, endYear).then((res => {
      if (res.status === 200) {
        setExporting(false)
        history.push('/downloads')
      }
    })).catch(err => {
      setExporting(false)
      console.log(err)
    })
  };

  useEffect(() => {
    setLoading(clientLoading);
    setCurrentStats({
      netRevenue: 0,
      explorationShare: 0,
      reports: 0,
      netRevenueChange: null,
      explorationShareChange: null,
      reportsChange: null,
    });
    setPrevStats({
      netRevenue: 0,
      explorationShare: 0,
      reports: 0
    });
    setReports([]);
  }, [clientLoading]);

  return (<>
    <LoadingNotification loadingText='Preparing export...' loading={exporting} />
    <div className="mt-6 mx-auto max-w-full">
      <div className="flex flex-grow flex-wrap gap-2 items-center">
        <div className="flex-1 flex items-center mb-2">
          <span className="flex-shrink-0 text-xl font-medium leading-6 text-gray-900 mr-2">{t('Overview for')}</span>
          <MonthYearDropdown
            defaultMonth={dateStart?.month}
            defaultYear={dateStart?.year}
            actionHandler={handleDateChange}
          />
          {/* <span className="flex-shrink-0 text-xl font-medium leading-6 text-gray-900 mr-2 ml-2">to</span>
          <MonthYearDropdown
            defaultMonth={dateEnd?.month}
            defaultYear={dateEnd?.year}
            actionHandler={handleDateEndChange}
          /> */}
        </div>
        <div className="mb-2 flex-shrink-0 flex-grow-0 flex gap-2">
          {/* {!loading && client
            ? (<Link
              to={urlJoin(basePath, `royalty-summary?month=${period.month}&year=${period.year}`)}
              className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Royalty Summary
            </Link>)
            : (<button
              type="button"
              className="animate-pulse inline-flex items-center rounded border border-transparent bg-gray-200 px-2.5 py-1.5 text-sm font-medium text-gray-400 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              Royalty Summary
            </button>)
          } */}

          {!loading && client
            ? (<Link
              to={urlJoin(basePath, `monthly-report?startMonth=${dateStart.month}&startYear=${dateStart.year}`)}
              className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {t('Monthly Pro-Active Report')}
            </Link>)
            : (<button
              type="button"
              className="animate-pulse inline-flex items-center rounded border border-transparent bg-gray-200 px-2.5 py-1.5 text-sm font-medium text-gray-400 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              {t('Monthly Pro-Active Report')}
            </button>)
          }
        </div>
      </div>
      <div>
      {invalidDate && <InfoBar text='Invalid date range' status='error' />}
      </div>
      <div className="mt-4 grid grid-cols-1 gap-5 lg:grid-cols-1 xl:grid-cols-3">
        {loading && <LoadingCard title={'Net Revenue'} icon={<BanknotesIcon className="h-6 w-6" aria-hidden="true" />} />}
        {!loading && (<div className="overflow-hidden rounded-lg bg-white shadow">
          <div className="p-5">
            <div className="flex">
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="truncate text-sm font-medium text-gray-500">{t('Net Revenue')}</dt>
                  <dd>
                    <div className="text-lg font-medium text-gray-900">
                      <NumberFormat
                        value={currentStats.netRevenue?.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </div>
                    <div className="text-xs font-medium text-gray-400">
                      <span>{t('Gross Revenue')}:&nbsp;</span>
                      <NumberFormat
                        value={currentStats.grossRevenue?.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </div>
                  </dd>
                </dl>
                <p
                  className={classNames(
                    currentStats.netRevenueChange ? 'text-green-600' : 'text-red-600',
                    'flex items-center text-xs'
                  )}
                >
                  {/* {currentStats.netRevenueChange ? (
                    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                  ) : (
                    <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                  )} */}
                  {/* <span className="text-sm">
                    <NumberFormat
                      value={prevStats.netRevenue.toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'from $'}
                    />
                  </span> */}
                </p>
              </div>
              <div className="flex-shrink-0">
                <div className="rounded-full bg-green-500 h-12 w-12 flex items-center justify-center shadow">
                  <BanknotesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
              </div>
            </div>
          </div>
        </div>)}

        {/* {loading && <LoadingCard title={'Exploration Share'} icon={<ReceiptPercentIcon className="h-6 w-6" aria-hidden="true" />} />}
        {!loading && (<div className="overflow-hidden rounded-lg bg-white shadow">
          <div className="p-5">
            <div className="flex">
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="truncate text-sm font-medium text-gray-500">Exploration Share</dt>
                  <dd>
                    <div className="text-lg font-medium text-gray-900">
                      <NumberFormat
                        value={currentStats.explorationShare.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </div>
                  </dd>
                </dl>
                <p
                  className={classNames(
                    currentStats.explorationShareChange ? 'text-green-600' : 'text-red-600',
                    'flex items-center text-xs'
                  )}
                >
                  {currentStats.explorationShareChange ? (
                    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                  ) : (
                    <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                  )}
                  <span className="text-sm">
                    <NumberFormat
                      value={prevStats.explorationShare.toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'from $'}
                    />
                  </span>
                </p>
              </div>
              <div className="flex-shrink-0">
                <div className="rounded-full bg-red-500 h-12 w-12 flex items-center justify-center shadow">
                  <ReceiptPercentIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
              </div>
            </div>
          </div>
        </div>)} */}

        {loading && <LoadingCard title={'Conflicts Resolved'} icon={<FireIcon className="h-6 w-6" aria-hidden="true" />} />}
        {!loading && (<HashLink
          to={urlJoin(basePath, `monthly-report?startMonth=${dateStart.month}&startYear=${dateStart.year}#conflict-resolution`)}
          className="overflow-hidden rounded-lg bg-white shadow">
          <div className="p-5">
            <div className="flex">
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="truncate text-sm font-medium text-gray-500">{t('Conflicts Resolved')}</dt>
                  <dd>
                    <div className="text-lg font-medium text-gray-900">
                      <NumberFormat
                        value={conflicts}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </div>
                  </dd>
                </dl>
                <p
                  className={classNames(
                    'text-gray-800',
                    // currentStats.reportsChange ? 'text-green-600' : 'text-red-600',
                    'flex items-center text-xs'
                  )}
                >
                  {/* {currentStats.reportsChange ? (
                    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                  ) : (
                    <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                  )} */}
                  {/* <span className="text-sm">
                    N/A
                    <NumberFormat
                      value={prevStats.reports.toFixed(0)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'from '}
                    />
                  </span> */}
                </p>
              </div>
              <div className="flex-shrink-0">
                <div className="rounded-full bg-yellow-500 h-12 w-12 flex items-center justify-center shadow">
                  <FireIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
              </div>
            </div>
          </div>
        </HashLink>)}

        {loading && <LoadingCard title={'Manual Claims'} icon={<VideoCameraIcon className="h-6 w-6" aria-hidden="true" />} />}
        {!loading && (<Link
          to={urlJoin(basePath, `monthly-report?startMonth=${dateStart.month}&startYear=${dateStart.year}&reportTab=YouTube Manual Claims`)}
          className="overflow-hidden rounded-lg bg-white shadow">
          <div className="p-5">
            <div className="flex">
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="truncate text-sm font-medium text-gray-500">{t('Manual Claims')}</dt>
                  <dd>
                    <div className="text-lg font-medium text-gray-900">
                      <NumberFormat
                        value={claims}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </div>
                  </dd>
                </dl>
                <p
                  className={classNames(
                    'text-gray-800',
                    // currentStats.reportsChange ? 'text-green-600' : 'text-red-600',
                    'flex items-center text-xs'
                  )}
                >
                  {/* {currentStats.reportsChange ? (
                    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                  ) : (
                    <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                  )} */}
                  {/* <span className="text-sm">
                    N/A
                    <NumberFormat
                      value={prevStats.reports.toFixed(0)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'from '}
                    />
                  </span> */}
                </p>
              </div>
              <div className="flex-shrink-0">
                <div className="rounded-full bg-blue-500 h-12 w-12 flex items-center justify-center shadow">
                  <VideoCameraIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
              </div>
            </div>
          </div>
        </Link>)}

      </div>

      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      {t('Report Name')}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {t('Net Revenue')}
                    </th>
                    {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Exploration Share
                    </th> */}
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {t('Export')}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {loading && (
                    <tr>
                      <td colSpan={4}>
                      <div className="w-full h-full flex justify-center items-center py-4">
                        <Spinner />
                        <span className="ml-2">{t('Loading data...')}</span>
                      </div>
                      </td>
                    </tr>
                  )}
                  {!loading && reports.map((report, idx) => {
                    const _name = report.report?.name;
                    // const _name = compareDates() === 0 ? report.report?.name : report.report__name;
                    return (
                      <tr key={idx}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {t(_name)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <NumberFormat
                            value={parseFloat(report.net_revenue)?.toFixed(2)}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <NumberFormat
                            value={parseFloat(report.exploration_share)?.toFixed(2)}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td> */}
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="flex gap-2">
                            <button
                              type="button"
                              onClick={() => getExport(client?.uid, _name, dateStart.month, dateStart.year, dateStart.month, dateStart.year)}
                              className="inline-flex items-center rounded border border-transparent bg-green-100 px-2.5 py-1.5 text-xs font-medium text-green-700 cursor-pointer hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                            >
                              <ArrowDownTrayIcon className="h-4 w-4 mr-1" aria-hidden="true" />
                              {t('Download File')}
                            </button>
                            {/* {report?.export
                              ? <a
                                href={report.export?.file_link}
                                type="button"
                                className="inline-flex items-center rounded border border-transparent bg-green-100 px-2.5 py-1.5 text-xs font-medium text-green-700 cursor-pointer hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                              >
                                <ArrowDownTrayIcon className="h-4 w-4 mr-1" aria-hidden="true" />
                                Download File
                              </a>
                              : <button
                                type="button"
                                onClick={() => getExport(client?.uid, _name, dateStart.month, dateStart.year, dateEnd.month, dateEnd.year)}
                                className="inline-flex items-center rounded border border-transparent bg-green-100 px-2.5 py-1.5 text-xs font-medium text-green-700 cursor-pointer hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                              >
                                <ArrowDownTrayIcon className="h-4 w-4 mr-1" aria-hidden="true" />
                                Download File
                              </button>
                            } */}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </>);
}

export default RoyaltyOverview;

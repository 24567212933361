import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useObserver } from 'mobx-react-lite';
import NumberFormat from 'react-number-format';
import {
  BanknotesIcon,
  ReceiptPercentIcon,
  DocumentTextIcon
} from '@heroicons/react/24/outline'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'

import MonthYearSelect from 'components/Form/MonthYearSelect';
import { classNames } from '../../../components/utils';
import { getSummary } from 'api/core';
import { useTranslation } from 'react-i18next';

const Overview = () => {
  const { t } = useTranslation();
  const componentIsMounted = useRef(true);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState();
  const [year, setYear] = useState();

  const [currentStats, setCurrentStats] = useState({
    grossRevenue: 0,
    explorationShare: 0,
    reports: 0,
    clients: 0,
    grossRevenueChange: null,
    explorationShareChange: null,
    reportsChange: null,
    clientsChange: null,
  });
  const [prevStats, setPrevStats] = useState({
    grossRevenue: 0,
    explorationShare: 0,
    reports: 0,
    clients: 0
  });
  const [reports, setReports] = useState([]);

  const handleDateChange = (newMonth, newYear) => {
    setMonth(newMonth);
    setYear(newYear);
  };

  const fetchData = useCallback(() => {
    setLoading(true);
    getSummary(month, year).then((res) => {
      if (res.data?.status === 'ok') {
        setCurrentStats({
          grossRevenue: res.data.gross_revenue,
          explorationShare: res.data.exploration_share,
          reports: res.data.reports,
          clients: res.data.clients,
          grossRevenueChange: res.data.gross_revenue >= res.data.prev_gross_revenue,
          explorationShareChange: res.data.exploration_share >= res.data.prev_exploration_share,
          reportsChange: res.data.reports >= res.data.prev_reports,
          clientsChange: res.data.clients >= res.data.prev_clients,
        });
        setPrevStats({
          grossRevenue: res.data.prev_gross_revenue,
          explorationShare: res.data.prev_exploration_share,
          reports: res.data.prev_reports,
          clients: res.data.prev_clients
        });
        setReports(res.data.reports_stats);
        setMonth(res.data?.month);
        setYear(res.data?.year);
      } else if (res.data?.status === 'no-assets') {
        setCurrentStats({
          grossRevenue: 0,
          explorationShare: 0,
          reports: 0,
          clients: 0,
          grossRevenueChange: null,
          explorationShareChange: null,
          reportsChange: null,
          clientsChange: null
        });
        setPrevStats({
          grossRevenue: 0,
          explorationShare: 0,
          reports: 0
        });
        setReports([]);
      }
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  }, [month, year])

  useEffect(() => {
    if (!componentIsMounted.current) return;
    fetchData();
    return () => {
      componentIsMounted.current = false;
    };
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [month, year, fetchData]);

  return useObserver(() => {
    return (<div>
      <div className="w-full mx-auto">
        <div className="flex-1 flex items-center min-w-0 mx-7">
          <h2 className="text-xl font-bold leading-7 text-gray-800 sm:truncate mr-2">{t('Overview for')}&nbsp;</h2>
          {!loading && <MonthYearSelect
            defaultMonth={month}
            defaultYear={year}
            actionHandler={handleDateChange}
          />}
        </div>
      </div>

      <div className="mt-4 mx-7 grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-3">

        <div className="overflow-hidden rounded-lg shadow bg-gradient-to-r from-red-50 to-blue-100">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <BanknotesIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="truncate text-sm font-medium text-gray-700">{t('Gross Revenue')}</dt>
                  <dd>
                    <div className="text-lg font-medium text-purple-900">
                      <NumberFormat
                        value={currentStats.grossRevenue.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </div>
                  </dd>
                </dl>
                <p
                  className={classNames(
                    currentStats.grossRevenueChange ? 'text-green-600' : 'text-red-600',
                    'flex items-center text-xs'
                  )}
                >
                  {currentStats.grossRevenueChange ? (
                    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                  ) : (
                    <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                  )}
                  <span className="text-sm">
                    <NumberFormat
                      value={prevStats.grossRevenue.toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'from $'}
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-hidden rounded-lg shadow bg-gradient-to-r from-yellow-50 to-red-100">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <ReceiptPercentIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="truncate text-sm font-medium text-gray-700">{t('Exploration Share')}</dt>
                  <dd>
                    <div className="text-lg font-medium text-pink-900">
                      <NumberFormat
                        value={currentStats.explorationShare.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </div>
                  </dd>
                </dl>
                <p
                  className={classNames(
                    currentStats.explorationShareChange ? 'text-green-600' : 'text-red-600',
                    'flex items-center text-xs'
                  )}
                >
                  {currentStats.explorationShareChange ? (
                    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                  ) : (
                    <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                  )}
                  <span className="text-sm">
                    <NumberFormat
                      value={prevStats.explorationShare.toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'from $'}
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="overflow-hidden rounded-lg bg-white shadow">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <UsersIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="truncate text-sm font-medium text-gray-500">Active Clients</dt>
                  <dd>
                    <div className="text-lg font-medium text-gray-900">{currentStats.clients}</div>
                  </dd>
                </dl>
                <p
                  className={classNames(
                    currentStats.clientsChange ? 'text-green-600' : 'text-red-600',
                    'flex items-center text-xs'
                  )}
                >
                  {currentStats.clientsChange ? (
                    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                  ) : (
                    <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                  )}
                  <span className="text-sm">
                    <NumberFormat
                      value={prevStats.clients}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'from '}
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="overflow-hidden rounded-lg shadow bg-gradient-to-r from-yellow-50 to-green-100">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <DocumentTextIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="truncate text-sm font-medium text-gray-700">{t('No. of Reports')}</dt>
                  <dd>
                    <div className="text-lg font-medium text-green-900">{currentStats.reports}</div>
                  </dd>
                </dl>
                <p
                  className={classNames(
                    currentStats.reportsChange ? 'text-green-600' : 'text-red-600',
                    'flex items-center text-xs'
                  )}
                >
                  {currentStats.reportsChange ? (
                    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                  ) : (
                    <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                  )}
                  <span className="text-sm">
                    <NumberFormat
                      value={prevStats.reports.toFixed(0)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'from '}
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="mt-6 mx-7 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      {t('Report Name')}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {t('Gross Revenue')}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {t('Exploration Share')}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {reports.map((report, idx) => (
                    <tr key={idx}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {report.report_feed__report__name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <NumberFormat
                          value={report.partner_revenue.toFixed(2)}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <NumberFormat
                          value={report.exploration_share.toFixed(2)}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>);
  });
}

export default Overview;

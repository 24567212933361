import { PrivateAPI } from "./utils";

export const getClients = () => {
  return PrivateAPI.get('/api/clients/');
};

export const createClient = (data) => {
  return PrivateAPI.post('/api/clients/', data);
};

export const editClient = (uid, data) => {
  return PrivateAPI.put(`/api/clients/${uid}/`, data);
};

export const createTransaction = (data, uid) => {
  return PrivateAPI.post(`/api/clients/${uid}/transactions/`, data);
};

export const getTransactions = (uid, page) => {
  var params = {}
  if (page) {
    params['page'] = page
  }
  return PrivateAPI.get(`/api/clients/${uid}/transactions/`, params);
};

export const createFile = (data, uid) => {
  return PrivateAPI.postfile(`/api/clients/${uid}/files/`, data);
};

export const getFiles = (uid, page) => {
  var params = {}
  if (page) {
    params['page'] = page
  }
  return PrivateAPI.get(`/api/clients/${uid}/files/`, params);
};

export const getBankUSDetails = uid => {
  return PrivateAPI.get(`/api/clients/${uid}/bank-us-details/`)
}

export const createBankUSChangeRequest = (uid, data) => {
  return PrivateAPI.post(`/api/clients/${uid}/bank-us-details/`, data)
}

export const updateBankUSDetail = (uid, id, data) => {
  return PrivateAPI.put(`/api/clients/${uid}/bank-us-details/${id}/`, data)
}

export const getAllBankUSDetails = params => {
  return PrivateAPI.get(`/api/client_requests/bank-us/`, params)
}

export const fetchBankUSDetails = id => {
  return PrivateAPI.get(`/api/client_requests/bank-us/${id}/`)
}

export const updateBankUSDetailStatus = (id, data) => {
  return PrivateAPI.post(`/api/client_requests/bank-us/${id}/status-update/`, data)
}
import React, { useContext } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { useObserver } from "mobx-react-lite"

import { AuthContext } from "stores/auth"

import UnderConstruction from "./static/UnderConstruction"

import ClientDetail from "./Clients/Detail"
import ClientInformation from "./Clients/ClientInformation"
import BankFormUS from "./Clients/BankInformation/BankFormUS"
import BankFormInt from "./Clients/BankInformation/BankFormInt"
import W9 from "./Clients/BankInformation/W9"
import W8BEN from "./Clients/BankInformation/W8BEN"
import W8BENE from "./Clients/BankInformation/W8BENE"

import ContractPage from "./Contracts/ContractPage"
import ContractForms from "./Contracts/ContractForms"

import AssetsList from "./Assets/List"
import DownloadView from "./Download"

import UsersList from "./Users/UsersList"
import UserForm from "./Users/UserForm"
import UserDetail from "./Users/UserDetail"
import AccountSelect from "./Auth/AccountSelect"

import MainWrapper from "containers/MainWrapper"
import LoadingPage from "./LoadingPage"
import Team from "./Team"

import UserManual from "./UserManual"
import ClientInformationManual from "./UserManual/ClientInformationManual"
import ClientsManual from "./UserManual/ClientsManual"
import DashboardManual from "./UserManual/DashboardManual"
import LoginManual from "./UserManual/LoginManual"
import LogOutManual from "./UserManual/LogOutManual"
import ProfileManual from "./UserManual/ProfileManual"
import UsersManual from "./UserManual/UsersManual"
import ClientReports from "./ClientReports"

import Reports from "./Reports/List"
import RoyaltySummaries from "./Reports/RoyaltySummaries"
import MonthlyAnnualReports from "./Reports/MonthlyAnnualReports"
import PlatformReports from "./Reports/PlatformReports"
import AssetReports from "./Reports/AssetReports"
import ConflictsReports from "./Reports/ConflictsReports"
import ClaimingReports from "./Reports/ClaimingReports"

const ClientRoutes = ({ loading }) => {
  const authStore = useContext(AuthContext)

  return useObserver(() => {
    if (loading) {
      return <LoadingPage />
    }

    if (authStore.selectedAccount) {
      return (
        <MainWrapper>
          <Switch>
            <Route path="/dashboard" component={ClientDetail} />
            <Route exact path="/users" component={UsersList} />
            <Route exact path="/users/add" component={UserForm} />
            <Route exact path="/users/:id" component={UserDetail} />
            <Route exact path="/users/edit/:id" component={UserForm} />
            <Route exact path="/profile/edit/" component={UserForm} />
            <Route exact path="/profile/" component={UserDetail} />

            <Route path="/team" component={Team} />

            <Route exact path="/info/" component={ClientInformation} />
            <Route
              exact
              path="/info/bank-info/form/us"
              component={BankFormUS}
            />
            <Route exact path="/info/bank-info/form/us/w9" component={W9} />
            <Route
              exact
              path="/info/bank-info/form/int"
              component={BankFormInt}
            />
            <Route
              exact
              path="/info/bank-info/form/int/w8ben"
              component={W8BEN}
            />
            <Route
              exact
              path="/info/bank-info/form/int/w8bene"
              component={W8BENE}
            />

            <Route
              exact
              path="/clients/contracts/view"
              component={ContractPage}
            />
            <Route
              exact
              path="/clients/contracts/form"
              component={ContractForms}
            />

            <Route path="/help" component={UserManual} />
            <Route
              path="/help/clientinformation"
              component={ClientInformationManual}
            />
            <Route path="/help/clients" component={ClientsManual} />
            <Route path="/help/dashboard" component={DashboardManual} />
            <Route path="/help/login" component={LoginManual} />
            <Route path="/help/logout" component={LogOutManual} />
            <Route path="/help/profile" component={ProfileManual} />
            <Route path="/help/users" component={UsersManual} />

            <Route exact path="/assets" component={AssetsList} />
            <Route exact path="/downloads" component={DownloadView} />

            <Route exact path="/reports" component={AssetReports} />
            <Route
              exact
              path="/reports/royalty-summaries"
              component={RoyaltySummaries}
            />
            <Route
              exact
              path="/reports/monthly-annual-reports"
              component={MonthlyAnnualReports}
            />
            <Route
              exact
              path="/reports/platform-reports"
              component={PlatformReports}
            />
            <Route
              exact
              path="/reports/asset-reports"
              component={AssetReports}
            />
            <Route
              exact
              path="/reports/conflicts-reports"
              component={ConflictsReports}
            />
            <Route
              exact
              path="/reports/claiming-reports"
              component={ClaimingReports}
            />

            <Route path="/admin" component={UnderConstruction} />

            <Route render={() => <Redirect to="/dashboard" />} />
          </Switch>
        </MainWrapper>
      )
    } else {
      return (
        <Switch>
          <Route path="/" component={AccountSelect} />
        </Switch>
      )
    }
  })
}

export default ClientRoutes

import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { CheckCircleIcon } from '@heroicons/react/20/solid'

const SuccessMessage = ({ show }) => {

  const history = useHistory()

  return ( show &&
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">Password reset link sent!</h3>
          <div className="mt-2 text-sm text-green-700">
            <p>Please check your email or spam messages and follow the link provided.</p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              <button
                onClick={()=>history.push("/login")}
                type="button"
                className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ForgotPassword = () => {

  const { register, handleSubmit } = useForm()
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const onSubmit = handleSubmit((data)=>{
    sendPasswordResetEmail(getAuth(), data.email)
    .then(()=>{
      setShowSuccessMessage(true)
    }).catch(err=> {
      console.log(err)
    })
  })

  return (
    <div>
      <div className="max-w-md m-auto mt-72 space-y-4">
        <SuccessMessage show={showSuccessMessage} />
        <div className="rounded-md shadow bg-white">
          <div className="border p-4">
            <div className="w-12 flex items-center">
              <img src="/assets/images/Logo.png" alt="exp-logo" /> <span className="ml-2 text-xl font-semibold">Exploration</span>
            </div>
          </div>
          <div className="p-8">
            <h1 className="text-center text-xl font-extrabold text-gray-900">Request reset password</h1>
            <form onSubmit={onSubmit}>
              <div className="space-y-2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  {...register('email', { required: true })}
                  className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                />
                <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-800 hover:to-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >Submit</button>
              </div>
            </form>
            <div className="w-full text-center pt-4">
              <Link to="/" className="text-center text-sm text-blue-500">Ready to login?</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword

import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { editClient } from 'api/expclient';

const EditClientForm = ({ uid, defaultValues }) => {
  let history = useHistory()

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "onChange",
    defaultValues: defaultValues
  });

  const onSubmit = (data) => {
    editClient(uid, data)
      .then(res => {
        history.push(`/clients/${uid}`)
      })
      .catch(err=>{
        console.log(err.response);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div>
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Client Information</h3>
              <p className="mt-1 text-sm text-gray-500">This information can only be edited by admins.</p>
            </div>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="slug" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Client Name
              </label>
              <div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  {...register('name', { required: true })}
                />
                {errors?.name?.type === "required" ? (
                  <div className="text-red-500 text-sm">Client Name is required</div>
                ) : null}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="slug" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Slug
              </label>
              <div>
                <input
                  type="text"
                  name="slug"
                  id="slug"
                  className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  {...register('slug', { required: true })}
                />
                {errors?.slug?.type === "required" ? (
                  <p className="text-red-500 text-sm">Slug is required</p>
                ) : null}
              </div>
            </div>

            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                CMS
              </label>
              <div>
                <input
                  type="text"
                  name="cms"
                  id="cms"
                  className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  {...register('cms', { required: true })}
                />
                {errors?.cms?.type === "required" ? (
                  <p className="text-red-500 text-sm">CMS is required</p>
                ) : null}
              </div>
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Own CMS?
                <span className="pl-2">
                  <input
                    id="own_cms"
                    name="own_cms"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    {...register('own_cms', { required: true })}
                  />
                </span>
              </label>
            </div> */}

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Client Status
              </label>
              <div>
                <select
                  type="text"
                  name="client_status"
                  id="client_status"
                  {...register('client_status', { required: true })}
                  className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                >
                  <option key="royalty" value="royalty">Royalty</option>
                  <option key="invoice" value="invoice">Invoice</option>
                  <option key="broker" value="broker">Broker</option>
                  <option key="terminated" value="terminated">Terminated</option>
                  <option key="invoice_royalty" value="invoice_royalty">Invoice and Royalty</option>
                </select>
              </div>
            </div>

            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Client ID
              </label>
              <div>
                <input
                  type="text"
                  name="external_client_id"
                  id="external_client_id"
                  className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  {...register('external_client_id', { required: true })}
                />
                {errors?.external_client_id?.type === "required" ? (
                  <p className="text-red-500 text-sm">Client ID is required</p>
                ) : null}
              </div>
            </div> */}

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="slug" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Royalties - Payee ID
              </label>
              <div>
                <input
                  type="text"
                  name="external_payee_id"
                  id="external_payee_id"
                  className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  {...register('external_payee_id')}
                />
              </div>
            </div>

            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Publisher ID
              </label>
              <div>
                <input
                  type="text"
                  name="external_publisher_id"
                  id="external_publisher_id"
                  className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  {...register('external_publisher_id', { required: true })}
                />
                {errors?.external_publisher_id?.type === "required" ? (
                  <p className="text-red-500 text-sm">Publisher ID is required</p>
                ) : null}
              </div>
            </div> */}

            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Is Active?
                <span className="pl-5">
                  <input
                    id="is_active"
                    name="is_active"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    {...register('is_active')}
                  />
                </span>
              </label>
            </div> */}
          </div>
        </div>

      <div className="flex justify-end">
        <Link
          to="/clients"
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Cancel
        </Link>
        <button
          type="submit"
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Save
        </button>
      </div>
    </div>
  </form>)
}

export default EditClientForm

import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import LoadingNotification from 'components/LoadingNotification';
import ClientSelect from './ClientSelect';
import { mergeClients } from 'api/core';

const Table = ({ state, dispatch, fetchData }) => {
  const history = useHistory();
  const [openClientSelect, setOpenClientSelect] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [merging, setMerging] = useState(false);
  const [error, setError] = useState(false);

  const handleMerge = (client) => {
    setSelectedClient(client);
    setOpenClientSelect(true);
  }

  const handleAssignClient = (clientId) => {
    if (setSelectedClient) {
      setMerging(true);
      mergeClients(clientId, selectedClient?.id).then((res) => {
        setMerging(false);
        history.push('/clients/' + res.data.uid + '/');
      }).catch(err => {
        console.log(err);
        setError(true);
        setMerging(false);
      });
    }
  };

  const handlePageClick = (event) => {
    if (event?.nextSelectedPage !== undefined) {
      dispatch({ type: 'SET_PAGE', payload: event.nextSelectedPage + 1 });
    }
  }

  return (<>
    <div className="mt-5 flex flex-col">
      <LoadingNotification loadingText='Merging clients...' loading={merging} />
      <LoadingNotification loadingText='An error occured while processing the merge.' loading={error} />
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <div className="group inline-flex">
                      Name
                      {/* <span className="ml-2 px-1 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                        <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                      </span> */}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Payee ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Publisher ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <div className="flex justify-end items-center">
                      Actions
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {state.loading && (<tr className='bg-white'>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="animate-pulse rounded-full bg-gray-200 w-44 h-5" />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="animate-pulse rounded-full bg-gray-200 w-20 h-5" />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="animate-pulse rounded-full bg-gray-200 w-24 h-5" />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="animate-pulse rounded-full bg-gray-200 w-24 h-5" />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex justify-end items-center gap-2">
                      <div className="animate-pulse rounded-md bg-gray-200 w-24 h-8" />
                      <div className="animate-pulse rounded-md bg-gray-200 w-24 h-8" />
                    </div>
                  </td>
                </tr>)}
                {!state.loading && state.clients?.map((client, idx) => {
                  // const summary = client?.revenues;
                  return (<tr key={client.id} className='bg-white'>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Link to={'/clients/' + client.uid + '/'} className='cursor-pointer text-md font-medium text-blue-800 group inline-flex'>
                        {client.name}
                      </Link>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {client.client_status === 'royalty' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                              <circle cx={4} cy={4} r={3} />
                            </svg>
                            Active
                        </span>
                      )}
                      {client.client_status === 'invoice' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-blue-400" fill="currentColor" viewBox="0 0 8 8">
                              <circle cx={4} cy={4} r={3} />
                            </svg>
                            Invoice
                        </span>
                      )}
                      {client.client_status === 'invoice_royalty' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-300 text-purple-800">
                            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-800" fill="currentColor" viewBox="0 0 8 8">
                              <circle cx={4} cy={4} r={3} />
                            </svg>
                            Invoice & Royalty
                        </span>
                      )}
                      {client.client_status === 'missing_info' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-yellow-400" fill="currentColor" viewBox="0 0 8 8">
                              <circle cx={4} cy={4} r={3} />
                            </svg>
                            Missing Info
                        </span>
                      )}
                      {client.client_status === 'broker' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-gray-400" fill="currentColor" viewBox="0 0 8 8">
                              <circle cx={4} cy={4} r={3} />
                            </svg>
                            Broker
                        </span>
                      )}
                      {client.client_status === 'terminated' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                          <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx={4} cy={4} r={3} />
                          </svg>
                          Terminated
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-500">
                      {client.external_payee_id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-500">
                      {client.external_publisher_id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end items-center">
                        <Link to={'/clients/' + client.uid + '/'}>
                          <button type="button"
                            className="ml-4 inline-flex items-center px-4 py-2 shadow-sm text-xs font-medium rounded text-gray-500 bg-gray-100 hover:text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:cursor-pointer"
                            // onClick={() => setAsInactive(client.uid)}
                          >
                            View Dashboard
                          </button>
                        </Link>
                        <Link to={`/clients/edit/?uid=${client.uid}`}>
                          <button type="button"
                            className="ml-4 inline-flex items-center px-4 py-2 shadow-sm text-xs font-medium rounded text-gray-500 bg-gray-100 hover:text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:cursor-pointer"
                            // onClick={() => setAsInactive(client.uid)}
                          >
                            Edit
                          </button>
                        </Link>
                        <button type="button"
                          className="ml-4 inline-flex items-center px-4 py-2 shadow-sm text-xs font-medium rounded text-gray-500 bg-gray-100 hover:text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:cursor-pointer"
                          onClick={() => handleMerge(client)}
                        >
                          Merge
                        </button>

                      </div>
                    </td>
                  </tr>
                )})}
              </tbody>
            </table>
            <div
              className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Page <span className="font-medium">{state.page}</span>&nbsp;of&nbsp;
                  <span className="font-medium">{state?.pages}</span>
                </p>
              </div>
              <div className="flex-1 flex justify-between sm:justify-end">
                <div className="">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onClick={handlePageClick}
                    pageRangeDisplayed={4}
                    pageCount={state?.pages}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    forcePage={state.page - 1}

                    containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    pageLinkClassName="relative inline-flex items-center border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    breakLinkClassName="relative inline-flex items-center border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    previousLinkClassName="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    nextLinkClassName="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    activeLinkClassName="font-strong text-gray-900 bg-gray-100"
                    disabledLinkClassName=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ClientSelect
      dialogTitle={'Merge ' + selectedClient?.name}
      isOpen={openClientSelect}
      setIsOpen={setOpenClientSelect}
      ctaAction={handleAssignClient}
      selectedClient={selectedClient}
    />
  </>);
}

export default Table;

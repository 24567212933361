import React, { useState, useRef, useContext } from "react"
import { AuthContext } from "stores/auth"
import { useForm, Controller } from "react-hook-form"
import {
  ArrowPathIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline"
import { useHistory } from "react-router-dom"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import Select from "react-select"
import { getStorage, ref, uploadBytes } from "firebase/storage"
import app from "firebase"
import uuid from "react-uuid"

import ProfileImage from "components/ProfileImage"
import { createUser, editUser } from "api/expauth"
import LoadingNotification from "components/LoadingNotification"
import { useTranslation } from "react-i18next"
import { userRoles } from "const"

const Form = ({ id, user, clients, isProfile }) => {
  let history = useHistory()
  const [profileImage, setProfileImage] = useState("")
  const [copied, setCopied] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const hiddenFileInput = useRef(null)
  const { t, i18n } = useTranslation()

  const storage = getStorage(
    app,
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_NAME
  )

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: user ? user : null,
  })
  const passwordValue = watch("password")
  const phoneNumber = watch("phone_number")
  const role = watch("role")

  function clean(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName]
      }
    }
    return obj
  }

  const onSubmit = async (data) => {
    if (profileImage) {
      let values = data
      var imageUid = uuid()
      var storageRef = ref(storage, `images/${imageUid}`)
      values["profile_image_uid"] = imageUid
      uploadBytes(storageRef, profileImage).then((res) => {
        console.log(res)
        saveData(values)
      })
    } else {
      saveData(data)
    }
  }

  const saveData = async (data) => {
    setSubmitting(true)
    let values = clean(data)
    if (user) {
      editUser(id, values)
        .then((res) => {
          setSubmitting(false)
          if (isProfile) {
            i18n.changeLanguage(res.data.preferred_language)
            history.push(`/profile/`)
          } else {
            history.push(`/users/${res.data.id}/`)
          }
        })
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          setSubmitting(false)
        })
    } else {
      createUser(values)
        .then((res) => {
          setSubmitting(false)
          history.push(`/users/${res.data.id}/`)
        })
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  const handleAssignedClientChange = (values) => {
    let assignedClients = values.map((client, idx) => {
      return client.value
    })
    setValue("assigned_clients", assignedClients)
  }

  const generatePassword = () => {
    const randomPassword =
      Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2)
    setValue("password", randomPassword)
    setCopied(false)
  }

  const copyPassword = () => {
    navigator.clipboard.writeText(passwordValue)
    setCopied(true)
  }

  const handleFileClick = (event) => {
    hiddenFileInput.current.click()
  }

  return (
    <>
      <LoadingNotification
        loadingText="Saving user information..."
        loading={submitting}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div>
              <div className="md:col-span-1 mb-4">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t("User Information")}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {t(
                    "Use a permanent email address where you can recieve emails."
                  )}
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="photo"
                className="block text-sm font-medium text-gray-700"
              >
                {t("Photo")}
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex items-center">
                  <ProfileImage
                    className="h-12 w-12 rounded-full"
                    user={user}
                  />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={hiddenFileInput}
                    onChange={(e) => {
                      setProfileImage(e.target.files[0])
                    }}
                  />
                  {profileImage && (
                    <span className="text-sm ml-3 text-gray-700">
                      {profileImage.name}
                    </span>
                  )}
                  <button
                    type="button"
                    onClick={handleFileClick}
                    className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    {t("Change")}
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  {t("Name")}
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="name"
                  className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                  {...register("name", { required: true })}
                />
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  {t("Email")}
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                  {...register("email", { required: true })}
                />
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  {t("Phone Number")}
                </label>
                <Controller
                  control={control}
                  name="phone_number"
                  render={(props) => (
                    <PhoneInput
                      {...props}
                      country="us"
                      preferredCountries={["us", "ph"]}
                      containerClass="max-w-lg flex bg-gray-50 border-gray-300"
                      inputClass="block min-w-full shadow-sm text-gray-800 focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                      value={phoneNumber}
                      onChange={(e) => {
                        setValue(`phone_number`, e)
                      }}
                    />
                  )}
                />
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  {t("Roles")}
                </label>
                <div className="mt-1 sm:mt-0">
                  <select
                    id="role"
                    name="role"
                    autoComplete="role"
                    {...register("role")}
                    className="max-w-lg block min-w-full focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option key="" value="">
                      --
                    </option>
                    {userRoles.map((ur) => (
                      <option key={ur.value} value={ur.value}>
                        {t(ur.label)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {role === "client" && (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                  <label
                    htmlFor="assigned-clients"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    {t("Assigned Clients")}
                  </label>
                  <div className="mt-1 sm:mt-0">
                    <Controller
                      control={control}
                      name="assigned_clients"
                      render={(props) => (
                        <Select
                          {...props.field}
                          isMulti
                          name="assigned_clients"
                          options={clients}
                          // onChange={handleAssignedClientChange}
                          className="max-w-lg block min-w-full border-none focus:ring-blue-500 focus:border-blue-500 text-border-none w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        />
                      )}
                    />
                  </div>
                </div>
              )}

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                <label
                  htmlFor="preferred_language"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  {t("Preferred Language")}
                </label>
                <div className="mt-1 sm:mt-0">
                  <select
                    id="preferred_language"
                    name="preferred_language"
                    autoComplete="preferred_language"
                    {...register("preferred_language")}
                    className="max-w-lg block min-w-full focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option key="en" value="en">
                      {t("English")}
                    </option>
                    <option key="es" value="es">
                      {t("Spanish")}
                    </option>
                  </select>
                </div>
              </div>

              {!user && (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Generate Password
                  </label>
                  <div className="mt-1 sm:mt-0">
                    <div className="max-w-lg flex rounded-md">
                      <span
                        className="inline-flex cursor-pointer items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm shadow-sm"
                        onClick={() => generatePassword()}
                      >
                        <ArrowPathIcon className="h-5 w-5" />
                      </span>
                      <input
                        type="text"
                        name="password"
                        id="password"
                        autoComplete="password"
                        {...register("password")}
                        className="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 shadow-sm"
                      />
                    </div>
                  </div>
                  {copied ? (
                    <span className="text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                      {" "}
                      Copied!
                    </span>
                  ) : (
                    <DocumentDuplicateIcon
                      onClick={() => copyPassword()}
                      className="inline-flex ml-2 mt-2 h-5 w-5 text-gray-500 cursor-pointer"
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-end">
            <button
              onClick={() => history.goBack()}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {t("Cancel")}
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {t("Save")}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default Form

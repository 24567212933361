import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from "stores/auth";
import Profile from "./components/Profile";
import OverviewChart from "./components/OverviewChart";
import RoyaltyOverview from "./components/RoyaltyOverview";
// import TopAssets from "./components/TopAssets";
import Rates from "./components/Rates";
import Team from "./components/Team"
import YearOverYear from './components/YearOverYear';


const Dashboard = ({ loading, client, stats, contract, uploadClientLogo, clientSource, toggleClientSource }) => {
  const authStore = useContext(AuthContext);
  // const selectedAccount = authStore.selectedAccount;
  const [dateStart, setDateStart] = useState({
    'month': stats?.max_month || (new Date()).getMonth() + 1,
    'year': stats?.max_year || (new Date()).getFullYear()
  });

  const [dateEnd, setDateEnd] = useState({
    'month': stats?.max_month || (new Date()).getMonth() + 1,
    'year': stats?.max_year || (new Date()).getFullYear()
  });

  useEffect(() => {
    if (stats?.max_month && stats?.max_year) {
      setDateStart({
        'month': stats?.max_month,
        'year': stats?.max_year
      })
      setDateEnd({
        'month': stats?.max_month,
        'year': stats?.max_year
      })
    }
  }, [stats]);

  return (<>
    {/* Main container */}
    <Profile
      loading={loading}
      client={client}
      contract={contract}
      uploadClientLogo={uploadClientLogo}
      clientSource={clientSource}
      toggleClientSource={toggleClientSource}
    />
    <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8 px-4 sm:px-6 lg:px-10 mt-8">
      {/* Left */}
      <div className="grid grid-cols-3 gap-6 lg:col-span-2 -mt-24">
        <div className="col-span-3 bg-white rounded-lg shadow divide-y divide-gray-200">
          <OverviewChart
            loading={loading}
            stats={stats}
            setDateStart={setDateStart}
            setDateEnd={setDateEnd}
          />
        </div>
        <div className="col-span-3">
          <RoyaltyOverview
            clientLoading={loading}
            client={client}
            dateStart={dateStart}
            dateEnd={dateEnd}
            setDateStart={setDateStart}
            setDateEnd={setDateEnd}
          />
        </div>

        {/* <div className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
          {client && stats?.reports_stats && <ReportsOverview stats={stats} />}
        </div> */}
      </div>
      {/* Right */}
      <div className="grid grid-cols-1 gap-6 lg:col-span-1 -mt-24">
        {/* <TopAssets client={client} assets={stats?.top_10_assets} /> */}
        {<Rates client={client} contract={contract} />}
        {<YearOverYear client={client} />}
        {/* {["superuser", "admin"].includes(authStore?.user.role) && <Team uid={client?.uid} selectedAccount={selectedAccount} />} */}
        {/* <Files client={client} /> */}
      </div>
    </div>
  </>)
}

export default Dashboard

import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';

import { createTransaction } from 'api/expclient';

const AddTransactionForm = ({ client, uid }) => {
  const { register, handleSubmit } = useForm()
  let history = useHistory()

  const onSubmit = (data) => {
    let values = data
    values['date'] = format(new Date(data['date']), 'MM/dd/yyyy')
    createTransaction(values, uid)
      .then(res => {
        history.push(`/clients/${uid}/`)
      })
      .catch(err=>{
        console.log(err.response);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div>
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Transaction Information</h3>
              <p className="mt-1 text-sm text-gray-500">You are adding a new transaction for {client?.name}.</p>
            </div>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="date" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Date
              </label>
              <input
                type="date"
                name="date"
                id="date"
                className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                {...register('date', { required: true })}
              />
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="amount" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Amount
              </label>
              <div className="max-w-lg flex rounded-md shadow-sm">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                  $
                </span>
                <input
                  type="number"
                  name="amount"
                  step="0.01"
                  id="amount"
                  autoComplete="amount"
                  {...register('amount', { required: true })}
                  className="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="amount" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Status
              </label>
              <div className="mt-1 sm:mt-0">
                <select
                  id="status"
                  name="status"
                  autoComplete="status"
                  {...register('status')}
                  className="max-w-lg block min-w-full focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option key="" value="">--</option>
                  <option key="sent" value="sent">Sent</option>
                  <option key="pending" value="pending">Pending</option>
                </select>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="amount" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Notes
              </label>
              <textarea
                id="notes"
                name="notes"
                rows={3}
                className="max-w-lg shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                {...register('notes')}
              />
            </div>
          </div>
        </div>

      <div className="flex justify-end">
        <Link
          to={`/clients/${client?.uid}/`}
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Cancel
        </Link>
        <button
          type="submit"
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Save
        </button>
      </div>
    </div>
  </form>)
}

export default AddTransactionForm

import { createBankUSChangeRequest, getBankUSDetails, updateBankUSDetail } from 'api/expclient';
import React, { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AuthContext } from 'stores/auth';
import { BankAccountTypes, CAN_VIEW_BANK_US } from 'const';
import hasClientMemberPerm from "utils/hasClientMemberPerm"
import { format, parse } from 'date-fns'

const BankFormUS = () => {

  const location = useLocation()
  const history = useHistory()
  const authStore = useContext(AuthContext);
  const selectedAccount = authStore.selectedAccount;
  const { uid } = useParams();
  const clientUid = selectedAccount ? selectedAccount.uid : uid;

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      account_type: BankAccountTypes.PERSONAL
    }
  })
  const [accountType, setAccountType] = useState(BankAccountTypes.PERSONAL)

  const canViewBankUS = hasClientMemberPerm(authStore.user, clientUid, CAN_VIEW_BANK_US)

  useEffect(() => {
    if (location?.state?.bankUSData) {
      reset({
        ...location.state.bankUSData,
        dob: format(parse(location.state.bankUSData?.dob, 'MM/dd/yyyy', new Date()), "yyy-MM-dd")
      })
      setAccountType(BankAccountTypes.choices().find((account) => account.value === location.state.bankUSData.account_type).label)
    } else {
      setLoading(true);
      getBankUSDetails(clientUid)
      .then(res=>{
        if (res.data.bank_us) {
          reset({
            ...res.data.bank_us,
            dob: format(parse(res.data.bank_us?.dob, 'MM/dd/yyyy', new Date()), "yyy-MM-dd")
          })
          setAccountType(BankAccountTypes.choices().find((account) => account.value === res.data.bank_us.account_type).label)
        }
      }).finally(()=>{
        setLoading(false)
      })
    }
  }, [clientUid, reset, location.state]);

  const onSubmit = handleSubmit((data)=>{
    setLoading(true)
    if (location?.state?.bankUSData) {
      updateBankUSDetail(clientUid, location.state.bankUSData.id, {
        ...data,
        dob: format(parse(data?.dob, 'yyyy-MM-dd', new Date()), "MM/dd/yyy"),
        account_type: BankAccountTypes.choices().find((account) => account.label === accountType).value,
        client: clientUid,
      })
      .then(res=>{
        // console.log("res", res)
        history.goBack()
      }).catch(err=>{
        console.log(err.response)
      }).finally(()=>{
        setLoading(false)
      })
    }
    else {
      createBankUSChangeRequest(clientUid, {
        ...data,
        dob: format(parse(data?.dob, 'yyyy-MM-dd', new Date()), "MM/dd/yyy"),
        account_type: BankAccountTypes.choices().find((account) => account.label === accountType).value,
        client: clientUid,
        action: 'update',
      }).then(_res=>{
        history.goBack()
      }).catch(err=>{
        console.log(err.response)
      }).finally(()=>{
        setLoading(false)
      })
    }
  })

  const onAccountTypeChange = (e) => {
    const checked = e.target.checked
    const name = e.target.name
    if (checked) {
      setAccountType(name)
      setValue('account_type', BankAccountTypes.choices().find(el => el.label === name).value)
    }
  }

  if (!canViewBankUS) {
    return (
      <div>Can not view this page</div>
    )
  }

  return (<>
        <div>
          <div className="w-full mx-auto">
            <div className="md:flex md:items-center md:justify-between my-5">
              <div className="flex-1 min-w-0 grid grid-cols-2">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Bank Form</h2>
                <div className="flex justify-end mt-2">
                  <div className='space-x-2'>
                    <div onClick={()=>{
                      history.goBack()
                    }} className="inline-flex button bg-white py-2 px-2 border border-gray-300 rounded-md shadow-sm text-xs font-medium text-gray-700 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Back</div>
                  {/* <Link to="/info/bank-info/form/int" type="button" className="button bg-white py-2 px-2 border border-gray-300 rounded-md shadow-sm text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Switch to International Form</Link> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                  <p className="mt-1 text-sm text-gray-500">Must match the details of the bank account owner</p>
                </div>

                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form onSubmit={onSubmit}>
                    <div className="grid grid-cols-6 gap-6">

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">First Name</label>
                        <input
                          {...register('first_name', { required: true })}
                          type="text"
                          id="first-name"
                          autoComplete="given-name"
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">Last Name</label>
                        <input
                          type="text"
                          {...register('last_name', { required: true })}
                          id="last-name"
                          autoComplete="family-name"
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">Date of Birth</label>
                        <input
                          {...register('dob', { required: true })}
                          type="date"
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">Contact Number</label>
                        <input
                          {...register('contact_number', { required: true })}
                          type="text"
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">Address / City / State / Zip</label>
                        <input
                          type="text"
                          {...register('address')}
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">Social Security # or Tax ID #</label>
                        <input
                          type="text"
                          {...register('ssti')}
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">Account Type</label>
                        <div className="flex justify-left space-x-1.5">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={onAccountTypeChange}
                              checked={accountType === 'personal'}
                              name="personal"
                              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Personal</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              onChange={onAccountTypeChange}
                              checked={accountType === 'checking'}
                              name="checking"
                              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Checking</label>
                          </div>
                        </div>
                        <div className="flex justify-left space-x-1.5">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={onAccountTypeChange}
                              checked={accountType === 'business'}
                              name="business"
                              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Business</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              onChange={onAccountTypeChange}
                              checked={accountType === 'savings'}
                              name="savings"
                              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Savings</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">Account Number</label>
                        <input
                          type="text"
                          {...register('account_number')}
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">Routing Number</label>
                        <input
                          type="text"
                          {...register('routing_number')}
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">Bank Name</label>
                        <input
                          type="text"
                          {...register('bank_name')}
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">Branch Contact Number</label>
                        <input
                          type="text"
                          {...register('bank_contact_number')}
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">Branch Address / City / State / Zip</label>
                        <input
                          type="text"
                          {...register('bank_address')}
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      {/* <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="formFile" className="text-sm font-medium text-gray-700">Accomplished W9 form</label>
                        <input className="form-control block w-full px-3 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" type="file" id="formFile" />
                      </div> */}

                      {/* <div className="col-span-6 sm:col-span-3 pt-8">
                        <Link to="/info/bank-info/form/us/w9" className="text-sm py-6 font-medium text-blue-600">Update W9 Details</Link>
                      </div> */}

                    </div>
                    <div className="flex justify-end mt-5">
                      <div onClick={()=>history.goBack()} type="button" className="ml-3 button bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Cancel</div>
                      <button
                        disabled={loading || (location?.state?.bankUSData?.status && location?.state?.bankUSData?.status !== 'pending')}
                        type="submit"
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-default">Request Update</button>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>
  </>);
}

export default BankFormUS;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import LoadingNotification from 'components/LoadingNotification';

import { createReportCategory } from 'api/core';

const CategoryForm = ({ setShowForm }) => {
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const { register, handleSubmit } = useForm()

  const onSubmit = (data) => {
    setLoading(true)
    setLoadingText('Creating report category...')
    createReportCategory(data)
      .then(res => {
        setLoading(false)
        setShowForm(false)
      })
      .catch(err=>{
        console.log(err.response);
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingNotification loadingText={loadingText} loading={loading} />
      <div className="space-y-6">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div>
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Report Category Information</h3>
              <p className="mt-1 text-sm text-gray-500">This information will only be displayed to admins.</p>
            </div>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="slug" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Report Category Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                {...register('name', { required: true })}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <Link
            to={`/admin/report-types`}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Cancel
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
            </button>
        </div>
    </div>
  </form>)
}

export default CategoryForm

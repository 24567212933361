import React, { useEffect, useState, useRef } from 'react';
import { format, parse } from 'date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';

import Spinner from 'components/Icons/Spinner';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const OverviewChart = ({ loading, stats, setDateStart, setDateEnd }) => {
  const { t } = useTranslation();
  const chartRef = useRef();
  const [chartData, setChartData] = useState();

  useEffect(() => {
    let chartLabels = []
    let chartStats = []

    for (const item in stats?.monthly_stats) {
      chartLabels.push(format(parse(item, 'yyyy-M', new Date()), 'MMM yyyy'));
      chartStats.push(stats?.monthly_stats[item]['revenue']);
    }

    setChartData({
      labels: chartLabels,
      datasets: [
        {
          label: 'Net Revenue',
          data: chartStats,
          backgroundColor: 'rgb(147,197,253)',
          // rgb(96,165,249)
          // rgb(37,99,235)
          borderRadius: 10,
        }
      ],
    });
  }, [stats]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      },
    },
    layout: {
      padding: {
        bottom: 15
      }
    }
  };

  const handleClick = (event) => {
    const elements = getElementAtEvent(chartRef.current, event);
    if (elements.length > 0) {
      const elem = elements[0];
      const idx = elem?.index;
      const key = chartData?.labels[idx];
      // const value = chartData?.datasets[0]?.data[idx];
      const dt = parse(key, 'MMM yyyy', new Date());

      setDateStart({
        'month': parseInt(format(dt, 'M')),
        'year': parseInt(format(dt, 'yyyy'))
      });
      setDateEnd({
        'month': parseInt(format(dt, 'M')),
        'year': parseInt(format(dt, 'yyyy'))
      });
    }
  };

  return (<div className="p-6 pb-10 h-80">
      <span className="text-xl font-medium">{t('Monthly Net Revenue')}</span>
      {!loading && chartData && <Bar
        ref={chartRef}
        options={options}
        data={chartData}
        onClick={(evt) => handleClick(evt)}
      />}
      {loading && (<div className="w-full h-full flex justify-center items-center">
        <Spinner />
        <span className="ml-2">{t('Loading data...')}</span>
      </div>)}
  </div>);
}

export default OverviewChart;

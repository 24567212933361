import React, { useContext, useEffect, useState } from "react"
import { Redirect, Link } from "react-router-dom"
import { useObserver } from "mobx-react"

import { AuthContext } from "stores/auth"
import { useQuery } from "utils"

import GoogleButton from "../../../components/Buttons/GoogleButton"
import { useForm } from "react-hook-form"
import { getAuth, signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth"
import { login } from "api/expauth"
import { assignUserToClient } from "api/users"
import { verifyInviteToken, registerInviteToken } from "api/users"
import { decodeJWT } from "utils/decodeJwt"

function Invitation() {
  const authStore = useContext(AuthContext)
  const auth = getAuth()
  let query = useQuery()
  const [clientData, setClientData] = useState(null)
  const [noAccount, setNoAccount] = useState(true)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('Cannot create user.')

  const { register, handleSubmit, getValues, formState: { errors } } = useForm()

  const onSubmit = async (data) => {
    setShowError(false)
    data["invite_token"] = query.get("token")
    data["username"] = data["email"]
    if (noAccount) {
      await registerInviteToken(data)
        .then((res) => {
          signInWithCustomToken(auth, res.data.custom_token).then((userCredential) => {
            let firebaseUser = userCredential.user
            data["accessToken"] = firebaseUser.accessToken
            data["assigned_clients"] = [decodeJWT(query.get("token")).uid]
            authStore.setLoading(true)
            login(data)
              .then((res) => {
                localStorage.setItem("accessToken", res.data.access)
                authStore.setUser(res.data.user)
                authStore.setLoggedIn(true)
                authStore.setLoading(false)
              })
              .catch((err) => {
                console.log(err.response)
              })
          }).catch(err => {
            console.log('error logging in')
          });
        })
        .catch((err) => {
          setShowError(true)
          setErrorMessage(err.response?.data?.errors)
          console.log(err.response)
        })
    } else if (!noAccount && !!clientData) {
      setShowError(false)
      await signInWithEmailAndPassword(auth, data["email"], data["password"])
        .then((userCredential) => {
          let firebaseUser = userCredential.user
          data["accessToken"] = firebaseUser.accessToken
          data["assigned_clients"] = [decodeJWT(query.get("token")).uid]
          authStore.setLoading(true)

          login(data)
            .then((res) => {
              localStorage.setItem("accessToken", res.data.access)
              authStore.setUser(res.data.user)
              authStore.setLoggedIn(true)
              authStore.setLoading(false)
            })
            .catch((err) => {
              setShowError(true)
              setErrorMessage('Login failed. Please try again.')
              console.log(err.response)
            })
        })
        .catch((err) => {
          setShowError(true)
          setErrorMessage('Login failed. Please try again.')
          console.log(err)
        })
    }
  }

  useEffect(() => {
    const inviteToken = query.get("token")
    if (inviteToken && inviteToken !== "") {
      verifyInviteToken(inviteToken).then((res) => {
        setClientData(res.data)
      })
    }
  }, [])

  return useObserver(() => {
    if (authStore.loggedIn) {
      return <Redirect to="/" />
    } else {
      return (
        <div id="login_page" className="min-h-full flex">
          <div className="flex-1 flex flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <div className="flex items-center">
                  <img
                    className="h-10 w-auto inline-block"
                    src="/assets/images/Logo.png"
                    alt="Exploration"
                  />
                  <h2 className="text-3xl font-extrabold text-gray-900 inline-block align-middle mx-5">
                    Client Portal
                  </h2>
                </div>

                {showError && ( <center className="mt-5 text-red-500 text-sm">{errorMessage}</center >)}

                {clientData !== null ? (
                  <>
                    <div className="mt-6">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="space-y-6"
                      >
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address
                          </label>
                          <div className="mt-1">
                            <input
                              {...register("email", { required: true })}
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                          {errors.email && ( <p className="text-red-500 text-sm">
                            {errors.email.type === 'required' ? 'This field is required.' : 'Invalid email address.'}
                          </p>)}
                        </div>

                        <div className="space-y-1">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Password
                          </label>
                          <div className="mt-1">
                            <input
                              {...register("password", { required: true })}
                              id="password"
                              type="password"
                              autoComplete="current-password"
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                          {errors.password && ( <p className="text-red-500 text-sm">
                            {errors.password.type === 'required' ? 'This field is required.' : 'Your passwords do not match.'}
                          </p>)}
                        </div>

                        {noAccount ? (
                          <div className="space-y-1">
                            <label
                              htmlFor="password"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Confirm Password
                            </label>
                            <div className="mt-1">
                              <input
                                {...register("confirmPassword", {
                                  required: true,
                                  validate: {
                                    equal: (v) => {
                                      const password = getValues("password")
                                      return v === password
                                    },
                                  },
                                })}
                                id="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              />
                            </div>
                            {errors.confirmPassword && ( <p className="text-red-500 text-sm">
                              {errors.confirmPassword.type === 'required' ? 'This field is required.' : 'Your passwords do not match.'}
                            </p>)}
                          </div>
                        ) : null}

                        <div className="flex items-center justify-center">
                          <div className="text-sm">
                            {noAccount ? (
                              <span
                                className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                onClick={() => setNoAccount(!noAccount)}
                              >
                                Already have an account? Login here
                              </span>
                            ) : (
                              <span
                                className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                onClick={() => setNoAccount(!noAccount)}
                              >
                                No account yet? Register here
                              </span>
                            )}
                          </div>
                        </div>

                        <div>
                          <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-blue-800 hover:from-blue-800 hover:to-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            {noAccount ? "Register" : "Login"}
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="mt-6 relative">
                      <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div className="w-full border-t border-gray-300"></div>
                      </div>
                      <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-gray-100 text-gray-500">
                          {" "}
                          OR{" "}
                        </span>
                      </div>
                    </div>

                    <div className="mt-2">
                      <GoogleButton invitationClientData={clientData} />
                    </div>
                  </>
                ) : (
                  <>
                    <h1>Please wait while we verify your invitation...</h1>
                  </>
                )}

                {/* <p className="mt-2 text-sm">
                <span className="font-medium text-gray-400 hover:text-gray-500">
                  By signing in, you agree to Exploration's Terms & Conditions and Privacy Policy.
                </span>
              </p> */}
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="/assets/images/Landing.jpg"
              alt=""
            />
          </div>
        </div>
      )
    }
  })
}

export default Invitation

import React, { useState, useEffect } from 'react';
import ReactExport from "react-data-export";
import { useTranslation } from 'react-i18next';
import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline'

import { downloadExport } from 'api/reports';
import { getMonthlyConflicts, getMonthlyClaims } from 'api/expdata';

const DownloadButton = () => {
  return (<button
    type="button"
    className="block inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  >
    <ArrowDownOnSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
    Download Report
  </button>)
}

const DownloadExport = ({ uid, name, isMech, startYear, startMonth, startMonthName }) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const { t } = useTranslation();
  const [downloadStatus, setDownloadStatus] = useState('pending');
  const [generalSummary, setGeneralSummary] = useState();
  const [conflictsData, setConflictsData] = useState();
  const [claimData, setClaimData] = useState();

  useEffect(() => {
    setDownloadStatus('processing');
    const fetchDetails = async () => {
      try {
        const exportData = await downloadExport(uid, startYear, startMonth, startYear, startMonth);

        setGeneralSummary(exportData?.data?.performance_summary);

        if (name) {
          const conflicts = await getMonthlyConflicts(startMonth, startYear, null, null, name);
          const claims = await getMonthlyClaims(startMonth, startYear, null, null, name);
          setConflictsData(conflicts?.data);
          setClaimData(claims?.data);
        }

        setDownloadStatus('success');
      } catch (error) {
        console.log(error);
        setDownloadStatus('failed');
      }
    };
    fetchDetails();
  }, []);

  const getPerformanceData = () => {
    const data = [
      [
        {'value': t('YouTube Monthly Report'), style: {font: {sz: "16", bold: true}}},
      ],
      [
        {'value': t('Summary Page'), style: {font: {sz: "16", bold: true}}},
      ],
      [],
      [
        {'value': startMonthName + ' ' + startYear},
      ],
      [
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': t('YouTube Revenue'), style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      ],
    ];

    generalSummary?.youtube_reports?.map((item, idx) => {
      data.push([
        {'value': item.report},
        {'value': item.net_revenue ? "$" + parseFloat(item.net_revenue).toFixed(2) : 0},
      ]);
    });

    data.push([]);

    data.push([
      {'value': t('YouTube Total Revenue'), style: {font: {bold: true}}},
      {'value': (generalSummary?.youtube_total_revenue ? "$" + parseFloat(generalSummary?.youtube_total_revenue).toFixed(2) : 0), style: {font: {bold: true}}},
    ]);

    if (generalSummary?.mechanical_reports) {
      data.push([
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': t('Mechanicals Revenue'), style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      ]);

      generalSummary?.mechanical_reports?.map((item, idx) => {
        data.push([
          {'value': item.report},
          {'value': item.net_revenue ? "$" + parseFloat(item.net_revenue).toFixed(2) : 0},
        ]);
      });

      data.push([
        {'value': t('Mechanicals Total Revenue'), style: {font: {bold: true}}},
        {'value': (generalSummary?.mechanicals_total_revenue ? "$" + parseFloat(generalSummary?.mechanicals_total_revenue).toFixed(2) : 0), style: {font: {bold: true}}},
      ]);
    }

    data.push([]);
    data.push([
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': t('Conflict Resolutions'), style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
    ]);
    data.push([
      {'value': t('Total assets resolved in our favor')},
      {'value': conflictsData?.total_assets || 0},
    ]);
    data.push([
      {'value': t('Total daily views resolved')},
      {'value': conflictsData?.total_daily_views || 0},
    ]);
    data.push([]);
    data.push([
      {'value': t('Notable Resolutions')},
    ]);
    data.push([
      {'value': t('Title')},
      {'value': t('Daily Views')},
      {'value': t('SR Asset ID')},
    ]);

    conflictsData?.notable_resolutions.map((rowData, idx) => {
      data.push([
        {'value': rowData.title},
        {'value': rowData.approx_daily_views},
        {'value': rowData.asset_id},
      ]);
    });

    const multiDataSet = [
      {
        columns: [
            {title: "", width: {wpx: 200}},
            {title: "", width: {wpx: 120}},
            {title: "", width: {wpx: 170}},
            {title: "", width: {wpx: 120}},
        ],
        data: data
      }
    ];

    return multiDataSet;
  };

  const ytManualClaimsData = () => {
    const sortedData = claimData?.yt_results?.sort((a,b) => b.videos_claimed - a.videos_claimed);
    const data = [];

    sortedData?.map((rowData, idx) => {
      data.push([
        {'value': rowData.song_title || ''},
        {'value': rowData.artist || ''},
        {'value': rowData.asset_id || ''},
        {'value': rowData.videos_claimed || 0},
      ])
    });
    
    if (data.length === 0) {
      data.push([
        {'value': ''},
        {'value': ''},
        {'value': ''},
        {'value': ''},
      ])
    }

    data.push([
      {'value': t('Total'), style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': claimData?.yt_claims || 0, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
    ]);

    const multiDataSet = [
      {
        columns: [
            {title: t('Song Title'), width: {wpx: 200}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Artist'), width: {wpx: 120}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Asset ID'), width: {wpx: 170}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Videos Claimed'), width: {wpx: 120}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        ],
        data: data
      }
    ];

    return multiDataSet;
  };

  const mechManualClaimsData = () => {
    const sortedData = claimData?.mech_results?.sort((a,b) => b.videos_claimed - a.videos_claimed);
    const data = [];

    sortedData?.map((rowData, idx) => {
      data.push([
        {'value': rowData.song_title || ''},
        {'value': rowData.artist || ''},
        {'value': rowData.platform || ''},
        {'value': rowData.asset_id || ''},
        {'value': rowData.videos_claimed || 0},
      ])
    });

    if (data.length === 0) {
      data.push([
        {'value': ''},
        {'value': ''},
        {'value': ''},
        {'value': ''},
      ])
    }

    data.push([
      {'value': t('Total'), style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': claimData?.mech_claims || 0, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
    ]);

    const multiDataSet = [
      {
        columns: [
            {title: t('Song Title'), width: {wpx: 200}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Artist'), width: {wpx: 120}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Platform'), width: {wpx: 120}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Asset ID'), width: {wpx: 170}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Videos Claimed'), width: {wpx: 120}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        ],
        data: data
      }
    ];

    return multiDataSet;
  };

  const ExportFile = () => {
    return (<>
      {isMech ? <ExcelFile
          filename={'YouTube Monthly Report'}
          element={<DownloadButton />}
        >
          <ExcelSheet dataSet={getPerformanceData()} name="Performance Summary" />
          <ExcelSheet dataSet={ytManualClaimsData()} name="YouTube Manual Claims" />
          <ExcelSheet dataSet={mechManualClaimsData()} name="Mechanicals Manual Claims" />
        </ExcelFile>
        : <ExcelFile
          filename={'YouTube Monthly Report'}
          element={<DownloadButton />}
        >
          <ExcelSheet dataSet={getPerformanceData()} name="Performance Summary" />
          <ExcelSheet dataSet={ytManualClaimsData()} name="YouTube Manual Claims" />
        </ExcelFile>
      }
    </>)
  };

  return (<>
    {downloadStatus === 'success' ?
      <ExportFile />
      : <>
        <button
          type="button"
          className="block inline-flex items-center gap-x-1.5 rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-400 shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
        >
          <ArrowDownOnSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Download Report
        </button>
      </>
    }
  </>)
}

export default DownloadExport;

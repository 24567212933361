import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';

import { AuthContext } from 'stores/auth'

import PublicRoute from './PublicRoute'
import ProtectedRoutes from './ProtectedRoutes'
import BlankWrapper from 'containers/BlankWrapper'

import LoadingPage from './LoadingPage'
import LogIn from './Auth/LogIn'
import ForgotPassword from './Auth/Password/ForgotPassword';
import Invitation from './Auth/Invitation';

function Router({ loading }) {
  const authStore = useContext(AuthContext);

  return useObserver(() => {
    if (loading) {
      return (<LoadingPage />);
    };

    if (!authStore.loggedIn) {
      return (<main className="h-full">
        <Switch>
          <PublicRoute
            exact
            path="/login"
            component={LogIn}
            layout={BlankWrapper}
          />
          <PublicRoute
            exact
            path='/forgot-password'
            component={ForgotPassword}
            layout={BlankWrapper}
          />
          {/* <PublicRoute exact path="/register" component={Register} layout={BlankWrapper} /> */}
          {/* <PublicRoute exact path="/register/success/" component={RegisterSuccess} layout={BlankWrapper} /> */}

          {/* <PublicRoute path="/account/verify/:verify_token/" component={VerifyEmail} layout={BlankWrapper} /> */}
          <PublicRoute path="/invite/" component={Invitation} layout={BlankWrapper} />

          <PublicRoute
            exact
            path="/"
            component={LogIn}
            layout={BlankWrapper}
          />
          {/* <Route path="/" component={AuthenticatedRoutes} /> */}
          <Route render={() => <Redirect to="/" />} />
          {/* <Route render={() => <Redirect to="/dashboard" />} />
          <Route render={() => <Redirect to="/client" />} /> */}
        </Switch>
      </main>)
    } else {
      return (<main className="h-full">
        <Switch>
          <Route path="/" component={ProtectedRoutes} />
        </Switch>
      </main>)
    }
  })


}

export default Router
